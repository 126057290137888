/* main color: #ce1320  */

body {
  background-color: #edefef !important;
  color: #5e5e5e !important;
}

img {
  max-width:100%;
}

.App {
  text-align: center;
  padding-bottom: 75px;
}

.App .mainButton{
  border-radius:25px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  background-color: #ffffff;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.App-header img {
  margin: 20px 0;
  max-width:100%;
}

.App-header .kontaktdaten{
  text-align: left;
  margin-top:15px !important;
}

.headerSearch {
  width: 100%;
}

.card {
  box-shadow: 0px 0px 19px #d7d7d7;
  -webkit-box-shadow: 0px 0px 19px #d7d7d7;
  -moz-box-shadow: 0px 0px 19px #d7d7d7;
 /* background-color:white !important; */
}

.card-body h3{
  margin-bottom:1.0rem;
  margin-top: 0.5rem;
}

.footermenu {
  position: fixed;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  padding: 10px;
  background-color: #ffffff;
}

.mainButton {
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  box-shadow: 0px 0px 13px #b3b3b3;
  -webkit-box-shadow: 0px 0px 13px #b3b3b3;
  -moz-box-shadow: 0px 0px 13px #b3b3b3;
  background-color: #f96302 !important
}

.mainButton a,
.mainButton a:hover {
  color: unset;
  text-decoration: unset;
}

.mainButton:active {
  /*background-color:#ffffff !important;*/
  filter: brightness(1.1);
  transition: all 0.5s ease-in-out;
}

.mainButton .title {
  color: #ffffff;
  font-weight: bold;
  font-size: 16px;
  margin: 0px 0 0 0;

}


.mainButton .icon {
  background-color: #17712d;
  background-color: #f96302;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 7px;
  margin: 0 auto;
 /* background-color: #e95c00; */
}

.mainButton .icon-red {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  padding: 5px;
  margin: 0 auto;
  /* background-color: #e95c00; */
}

.mainButton .icon img,
.mainButton .icon-red img {
  width: 100%;
  height: 100%;
}

.mainButton .col{
  padding:0;
}

.menuItem {
  margin: 0;
}

.menuItem a,
.menuItem a:hover {
  color: #5c5c5c;
  text-decoration: none;
}

.openingHours {
  text-align: left;
  margin: 10px 0;
}

.openingHours .day {
  font-weight: bold;
}

.openingHours .time {
  text-align: right;
}

.kontaktdaten .icon{
  color: #ce1320;
}

.kontaktdaten .text{
  padding-left:0px;
  padding-left:15px;
  width: fit-content;
}

.kontaktdaten a{
  text-decoration:none;
  color: inherit;
}

.logozeile{
  width:50%;
}

.contact {
  text-align: left;
}

.contact .icon {
  color: #f96302;
  font-size: 20px;
  text-align: center;
}

.powered {
  text-align: center;
  font-size: 20px;
  margin-bottom:0;
  padding-bottom: 0px;
}

.empfehlung a {
  text-decoration: none;
  color: unset;
}

.empfehlung .container {
  padding: 0;
}

.openingHours,
.contact {
  margin-top: 20px;
}

@media  screen and (max-width: 768px) {
  .App-header .logozeile{
    width:35%;
    max-width:35%;
  }
}

.App .footermenu{
  width:100%;
  max-width:100%;
}

.lieferbox{
  background-color:white;
  /*border: 1px solid grey;*/
  border-radius:20px;
  width:100%;
  box-shadow: 0px 0px 19px #d7d7d7;
  -webkit-box-shadow: 0px 0px 19px #d7d7d7;
  -moz-box-shadow: 0px 0px 19px #d7d7d7;
}

.label-title{
  margin-bottom:10px;
}

.slabel-hidden{ /* HIDE RADIO */
  visibility: hidden; /* Makes input not-clickable */
  position: absolute; /* Remove input from document flow */
}

.lieferbox:has(input:focus){
  background-color:#f5f5f5;
  transition: all 0.2s ease-in-out;
}

.lieferbox input{
  display:none;
}

label img{
  max-width:80px;
}

.container:has(.notActive) .lieferartContainer{
  height:0px;
  visibility: hidden;
}

.container:has(.active) .lieferartContainer{
  height:inherit;
  visibility: visible;
  margin-top: 10px;
}

.active .lieferartContainer{
  height:inherit;
}

.container:has(.qr-notActive) .qr-block{
  height:0px;
  visibility: hidden;
}

.container:has(.qr-active) .qr-block{
  height:inherit;
  visibility: visible;
  margin-top: 10px;
}

.qr-active .qr-block{
  height:inherit;
}

.lieferartContainer{
  font-weight:500;
  color:#787878;
  /* Für externe Bestellung */
  display:none;
}

form .form-row{
  margin-top:20px;
  margin-left:0px;
  margin-right:0px;
  margin-bottom:10px;
  padding-top:20px;
  background-color:white;
  box-shadow: 0px 0px 19px #d7d7d7;
  -webkit-box-shadow: 0px 0px 19px #d7d7d7;
  -moz-box-shadow: 0px 0px 19px #d7d7d7;
  border-radius:20px;
}

.form-row label{
  margin-left:30px;
  margin-right:30px;
  width:100%;
}

form .fileupload{
  border: 2px dashed #f96302;
  width: 90%;
  margin: auto;
  margin-bottom:5px;
  padding:20px;
  background-color: #ffffff82;
}

.fileupload input{
  margin-left:20px;
  margin-right:20px;
  background-color:#fff;
}

.formular-flex-item label{
  text-align:left;
}

.upload-image{
  margin-bottom:20px;
}

.form-row:has(.fileupload){
  box-shadow: none;
  background: none;
  padding-top:5px;
}

@media (min-width: 768px){
.formular-flex {
    display: flex;
}
.formular-flex-item:last-child {
  margin-left: 6%;
}
.formular-flex-item {
  width: 49%;
}
}

.form-row [type=submit]{
  width:fit-content;
  display: flex;
}

.submitbutton{
  padding-left:10px;
  margin-bottom:20px;
}

form input{
  border: 1px solid #dedede;
  border-radius: 2px;
  width:90%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}